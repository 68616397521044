import React from 'react'

import PropTypes from 'prop-types'

import Review from './review'
import './client-feedback-old.css'

const ClientFeedbackOld = (props) => {
  return (
    <section className="client-feedback-old-section">
      <div className="client-feedback-old-container">
        <header className="client-feedback-old-header">
          <header className="client-feedback-old-left">
            <h2 className="client-feedback-old-heading Heading colortexter colortext section-heading1 px30">
              {props.Heading}
            </h2>
          </header>
          <div className="client-feedback-old-right">
            <p className="client-feedback-old-paragraph section-description Content texter">
              {props.Paragraph}
            </p>
          </div>
        </header>
        <main className="client-feedback-old-cards">
          <div className="client-feedback-old-container1">
            <Review
              Quote="I am very pleased with their performance, particularly their initiative and creativity. They are a steady source of good ideas to build site traffic and are clear as to my role and their's. They generally do most of the work themselves."
              Author="Gavin Ashworth"
              Position="Chief Executive Officer"
              rootClassName="review-root-class-name7"
            ></Review>
            <Review
              Quote="The experience with them is awesome and have a good rapport. The entire team is highly responsive, reliable as well as hardworking. The team is extremely skilled at web design and creation. The development team was punctual and charged affordable rates."
              Author="Gramey"
              Position=""
              rootClassName="review-root-class-name8"
            ></Review>
            <Review
              Quote="It was a challenging project and while we didn't get everything in, The team did a good job and was responsive. Very happy with their commitment to making sure we released and staying up late and over the weekend. That's the sign of a great partnership."
              Author="Brent McKendry"
              Position="CEO at FINCO TECHNOLOGY"
              rootClassName="review-root-class-name8"
            ></Review>
          </div>
          <div className="client-feedback-old-container2">
            <Review
              Quote="We have worked with Webmobril since 2017. Yes, we have our ups and downs. They never fail to give their utmost best. They are attentive to my needs and go the extra mile at no additional cost. They are committed to improving their service and very open to receiving feedback. It has been an amazing journey."
              Author="Ben Koh"
              Position="Founder &amp; Managing Director"
              rootClassName="review-root-class-name6"
            ></Review>
            <Review
              Quote="WebMobril has done an amazing job on the creation Of my computer platform. My knees were very specific and I didn’t know if I could find programmers that could program the nuance I needed in my platform but WebMobril pulled it off with flying colors. Being a programmer myself I was able to appreciate the challenge I gave the team at webmobril. They were able to understand exactly what I needed and then delivered it with a minimum of oversight."
              Author="John Alexander"
              Position="Author, Real Estate Investor, Real Estate Trainer"
              rootClassName="review-root-class-name9"
            ></Review>
          </div>
          <div className="client-feedback-old-container2">
            <Review
              Quote="Thank you for bringing my app to existence with your patience, understanding, and resourcefulness. In the beginning, I have doubts as to whether this is feasible – after all, we are continents apart and can only meet and talk over the internet. But step by step, an idea becomes a fun game app available both in the Apple App Store and Google Play Store.
              Swaraj, thank you for using your technical skill to manage moving musical notes."
              Author="Amanda Pi"
              Position="Founder"
              rootClassName="review-root-class-name6"
            ></Review>
            <Review
              Quote="Swaraj has a team of great young developers. In each conversation, they were very enthusiastic and eager to help build a good product. They exceeded expectations in the amount of work done. They sometimes needed reminders on the timelines for deliveries, but all work was completed. The only reason we did not give 5 stars is that we would
              have preferred them to be more proactive in fixing bugs. However, all bugs were fixed when externally prompted. We work with them again on future projects."
              Author="Oxford VR"
              Position="CEO"
              rootClassName="review-root-class-name9"
            ></Review>
          </div>
          {/* <div className="client-feedback-old-container3"></div> */}
        </main>
        <div className="client-feedback-old-view-more">
          <p className="client-feedback-old-text">{props.Text}</p>
        </div>
      </div>
    </section>
  )
}

ClientFeedbackOld.defaultProps = {
  Text: 'View more',
  Heading: 'What clients say about us',
  Paragraph:
    'Gaining a competitive edge through our customer centric approach and delivering exemplary games, we have managed to build a strong clientele relationship.',
}

ClientFeedbackOld.propTypes = {
  Text: PropTypes.string,
  Heading: PropTypes.string,
  Paragraph: PropTypes.string,
}

export default ClientFeedbackOld
