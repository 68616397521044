import React from 'react'
import PropTypes from 'prop-types'


import './ropeCard.css'

const RopeCard = (props) => {
  return <a target="_blank" href={props.link}><div className={`card-container cursor_pointer ${props.perk} `} style={{ backgroundImage: `url(${props.image})` }}><div className='icon'></div>
    <div className='hoverDiv'><span className='text'>{props.app}</span></div>
  </div></a>
}

RopeCard.defaultProps = {
  perk: '',
  link: '',
  app:'Test App',
  image: 'https://images.unsplash.com/photo-1680034200919-26a16a426d34?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
}

RopeCard.propTypes = {
  perk: PropTypes.string,
  link: PropTypes.string,
  app: PropTypes.string,
  image: PropTypes.string,
}

export default RopeCard