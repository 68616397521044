import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import Header from "../components/header";
import Footer from "../components/footer";
import "./privacy-policy.css";
import { request } from "../Api";

const PrivacyPolicy = (props) => {
  const [teams, setTeams] = useState([]);
  const getData = async () => {
    const promise = await request("team/active", {}, "get");

    if (promise.error) {
      console.log("error");
    } else {
      setTeams(promise.response);
      console.log(promise.response);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {/* <Helmet>
        <title>
          Unity Game Development Company | Custom Game Development Company
        </title>
        <meta
          name="keywords"
          content="Top Iphone Game App Development Company, Top Unity Game Development Company, Best Ios Games App Development, Best, Android Game App Development, Custom Game Development Company, Unity Game Development Services"
        />
        <meta
          name="description"
          content="WebMobril Gaming Studios is a top-notch Unity game development company in India, we specialize in building 3D games for iOS, Android, and other platforms."
        />
        <meta name="og:site_name" content="Webmobril Gaming Studioz" />
        <meta name="og:region" content="IN" />
        <meta name="og:country-name" content="INDIA" />
        <meta name="language" content="english" />
      </Helmet> */}

      <Header rootClassName="header-root-class-name1"></Header>
      <div
        style={{
          textAlign: "center",
          marginTop: "150px",
          marginRight: "40px",
          marginLeft: "40px",
          marginBottom: "40px",
        }}
      >
        <p style={{ textAlign: "center" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <span style={{ color: "white" }}>
                <span style={{ fontSize: "xx-large" }}>
                  <h1>Privacy Policy</h1>
                </span>
              </span>
            </span>
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <span style={{ color: "white" }}>
                This Privacy Policy describes how WebMobril Gaming Studioz or
                any of its subsidiaries or affiliates (collectively "WebMobril")
                treats personal information collected and received in connection
                with your use of wmgamingstudioz.com and/or WebMobril's games
                and applications. This includes games you have downloaded or are
                about to download, applications created by WebMobril and
                available through social networking sites, games created by
                WebMobril and available through mobile devices, software running
                on WebMobril servers, content, related documentation,
                information on the WebMobril domain, and information made
                available by us. The expressions “You” “Your” or “User(s)” refer
                to any person who accesses or uses the Website for any purpose.
              </span>
            </span>
          </span>
        </p>{" "}
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <span style={{ color: "white" }}>
                Your privacy is important to us, and as stated in this Privacy
                Policy, we have created the Service to prevent unauthorized
                disclosure of information about you to others. This Privacy
                Policy describes how we collect, utilize, and, in certain cases,
                disclose your personally identifiable information. Please read
                our Privacy Policy carefully and feel free to contact us if you
                have any questions.
              </span>
            </span>
          </span>
        </p>
        <br />
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <span style={{ color: "white" }}>
                By registering for, accessing, using, and/or downloading any
                Service, you agree to WebMobril's Privacy Policy and that you
                will adhere to our Terms of Service. You consent, among other
                things, to collecting and using your personal information as
                described in this Privacy Policy, as such policy may be changed
                from time to time. We reserve the right to alter the Privacy
                Policy at any time due to unforeseen circumstances or
                technological advancements. You should revisit this page on a
                regular basis to review any changes to the Privacy Policy.
              </span>
            </span>
          </span>
        </p>
        <br />
        <br />
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <h2 align="center">
                <span style={{ color: "white" }}>Types of Information</span>
              </h2>
            </span>
          </span>
        </p>
        <br />
        <br />
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <h3>
                <span style={{ color: "white" }}>Personal Information :</span>
              </h3>
            </span>
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <span style={{ color: "white" }}>
                Personal information (used to identify a user), includes, but is
                not limited to, first and last names, physical temporary and
                personal addresses, telephone numbers, date of birth, age,
                gender, e-mail address, or any other contact information,
                financial information such as bank account details, credit/debit
                card details, or such other payment information. Furthermore, a
                person's Sensitive Personal Data or Information ("SPDI")
                contains Personal Information about that individual pertaining
                to passwords, financial information (bank accounts, credit and
                debit cards, or other payment instruments), and physical
                information. The Company only collects Personal Information that
                is required for its intended purpose.
              </span>
            </span>
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <h3>
                <span style={{ color: "white" }}>
                  Business Information :&nbsp;
                </span>
              </h3>
            </span>
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <span style={{ color: "white" }}>
                To identify a user&rsquo;s business
                <em>
                  <span style={{ color: "white" }}>
                    , collected information includes, but is not limited to, the
                    entity's name and address, date of incorporation, e-mail
                    address, contact information of the registered office,
                    company identification number (CIN), financial information
                    including bank account details, PAN details, names of the
                    directors, and such other information (hereinafter referred
                    to as "Business Information").&nbsp;
                  </span>
                </em>
              </span>
            </span>
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <h3>
                <span style={{ color: "white" }}>
                  Non-Personal Information :&nbsp;
                </span>
              </h3>
            </span>
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  Non-personal information is information that does not
                  specifically identify an individual or business but includes
                  information about you, such as your browser type, the URLs of
                  previous websites you visited, your Internet Service Provider
                  (ISP), your operating system, and your Internet Protocol (IP)
                  address. The Company may collect non-personal information such
                  as how many people visit the website, the pages they view,
                  their IP address, and the type of browser they use when
                  visiting the website (collectively referred to as
                  "Non-Personal Information"). The Company may also collect
                  Non-Personal Information that you freely supply, such as
                  responses to a questionnaire or survey sponsored by the
                  Company.
                </span>
              </em>
            </span>
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <h3>
                <span style={{ color: "white" }}>
                  Usage Information :&nbsp;
                </span>
              </h3>
            </span>
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <span style={{ color: "white" }}>
                Usage Information includes, but is not limited to, all data and
                information collected automatically through the website (or by
                third-party analytics service providers), by use and access of
                the website in the nature of system administrative data,
                statistical and demographic data, and operational information
                and data generated by or characterizing use of the website,
                including, but not limited to, Non-Personal Information,
                cookies, website traffic, time spent on the website. Personal
                Information, Business Information, Non-Personal Information, and
                Usage Information are all hereinafter referred to as
                "Information" in this document.
              </span>
            </span>
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <h3>
                <span style={{ color: "white" }}>We Collect</span>
              </h3>
            </span>
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <span style={{ color: "white" }}>
                WebMobril Gaming Studioz gives you a number of options regarding
                what information you will give us:
              </span>
            </span>
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  <br />
                  Your profile, including any information (name, address,
                  gender), will be registered with WebMobril Gaming Studioz when
                  you establish a profile. When identifying yourself on a
                  WebMobril Gaming Studioz site, you may use a WebMobril Gaming
                  Studioz account. You may identify yourself on a WebMobril
                  Gaming Studioz site by using a social network account, in
                  which case WebMobril Gaming Studioz may obtain extra
                  information about your profile.
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  We will collect and record the information you have supplied
                  in each case. We normally do not collect any "Personal
                  Information" about our users other than names and email
                  addresses submitted at your choice, with the exception of
                  invoicing and payments, where we keep your billing information
                  such as name, street address, and phone number. We may provide
                  you with the option of submitting additional information about
                  yourself (such as gender, age, occupation, hobbies, interests,
                  zip code, and more), or we may be able to collect that
                  information from social networking systems on which you have
                  used WebMobril Gaming Studioz Games or Apps (in accordance
                  with the terms of use of those systems), which would then be
                  covered by this Privacy Policy.
                  <br />
                  <br />
                  In addition to information about yourself, you may opt to
                  share information about yourself in other Content, such as
                  games. Any interaction you have with other players is regarded
                  as public and at your own discretion. When you use a Social
                  Networking System (SNS) App on your social network page or on
                  someone else's page, we may record information about that SNS
                  App's use, as well as other information visible on your social
                  network page, in accordance with the relevant terms of use
                  associated with that page.
                  <br />
                  <br />
                  Because most WebMobril Gaming Studioz Software is delivered
                  through Apps, it is your responsibility to read and accept
                  their Terms of Service and Privacy Policy before proceeding.
                  When you use an App, which includes smartphones and other
                  devices such as tablets, you agree that information about your
                  use of the Service through your mobile device and carrier may
                  be communicated to us. This information includes but is not
                  limited to, your carrier, device, geo-location, IP address,
                  phone number, country, and any other information you supply.
                  <br />
                  <br />
                  We may also access your contacts with your consent. Your
                  carrier's standard fees and rates, including SMS/text message
                  costs, will continue to apply, and you accept responsibility
                  for all charges. When you invite new members to your network
                  or send messages over the Service, we collect and store the
                  information linked with such communications, including email
                  addresses and content, on secure servers.
                  <br />
                  <br />
                  We may enhance your profile with information about you
                  obtained from other sources, including but not limited to
                  newspapers and Internet sources such as blogs, instant
                  messaging services, WebMobril Gaming Studioz Games, and other
                  users. Links to additional websites may be found on WebMobril
                  Gaming Studioz. We are not responsible for the privacy
                  policies of third-party websites. We advise users to read the
                  privacy policies of each and every website that collects
                  personally identifiable information when they leave our site.
                  This Privacy Statement only applies to information gathered by
                  WebMobril Gaming Studioz.
                  <br />
                  <br />
                  You should also be informed that the majority of the content
                  offered by WebMobril Gaming Studioz Games originates from
                  another content source. Other content providers may store
                  "cookies" on your browser or collect information about you,
                  such as by requesting you to contribute information through
                  the site itself.
                  <br />
                  <br />
                  Last but not least, in order to offer the best service
                  possible as well as to enable us to create some internal
                  reports and make recommendations, we collect aggregate data
                  about the use of Service, including details about users who
                  access the WebMobril Gaming Studioz website, play WebMobril
                  Gaming Studioz games, use SNS apps on other websites, or use
                  mobile apps, such as IP addresses, browser type, browser
                  language, referring / exit pages and URLs, other
                  browser history, and platform type, number of clicks, domain
                  names, landing pages, pages viewed and the order of those
                  pages, the amount of time spent on particular pages, and the
                  date and time (“Activity Information”).
                  <br />
                  <br />
                  By using our Service, you agree that we may keep all the
                  information mentioned above in an anonymous form for as long
                  as WebMobril Gaming Studioz deems necessary.
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontFamily: "Times New Roman, serif" }}>
            <span style={{ fontSize: "medium" }}>
              <h3>
                <span style={{ color: "white" }}>
                  Children under the Age Of 13
                </span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  WebMobril Gaming Studioz does not deliberately solicit
                  personal information from any users under the age of 13 and
                  such individuals are not knowingly permitted to register.
                  Please do not attempt to register for WebMobril Gaming Studioz
                  if you are under 13 or transmit any personal information to
                  us, such as your name, address, phone number, or email
                  address. No personal information about anybody under 13 may be
                  submitted to or posted on WebMobril Gaming Studioz. If we come
                  to know that we have gathered personal information from a
                  child under 13, we shall take all reasonable steps to have it
                  removed.&nbsp;
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>Please email to </span>
              </em>
              <span style={{ color: "#0000ff" }}>
                <u>
                  <a href="mailto:info@wmgamingstudioz.com">
                    info@wmgamingstudioz.com
                  </a>
                </u>
              </span>
              <em>
                <span style={{ color: "white" }}>
                  {" "}
                  if you think we may have any information from or about a kid
                  under the age of 13.&nbsp;&nbsp;
                </span>
              </em>
            </span>
          </span>
        </p>
        <p align="justify">&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <h3>
                <span style={{ color: "white" }}>
                  Children between the Ages of 13 and 18
                </span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  Before giving any personal information to anybody online, we
                  advise youngsters over 13 and under the age of 18 to get their
                  parents' approval.
                </span>
              </em>
            </span>
          </span>
        </p>
        <p align="justify">&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <h3>
                <span style={{ color: "white" }}>
                  How We Use Your Information
                </span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  We do not sell or lease your "Personally Identifiable
                  Information" to third parties. However, there are times when
                  we may utilize non-personally identifying information about
                  you. In order to assess the kind of material you are
                  interested in seeing and to help any advertisers target their
                  advertising, we may also use publicly available information
                  that has been gathered. Your name, email address, phone
                  number, and other information stored on our system are also
                  used to contact you when a new product or service is released,
                  to provide service notifications, and to ask for your opinions
                  and comments. These emails or SMS/text notifications will also
                  include links for managing preferences and, if necessary, a
                  way to&nbsp;
                </span>
              </em>
              <span style={{ color: "white" }}>opt out</span>
              <em>
                <span style={{ color: "white" }}>.</span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  With your permission, we may send updates and relevant
                  communications via push notifications to your mobile device.
                  Your mobile device could also get local alerts from us. You
                  may control notifications from the "Settings" section of the
                  mobile app or your device.
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  Your information is used by the Company to: (a) manage your
                  account; (b) respond to your questions about its offerings and
                  the transactions made on the website; (c) inform you of the
                  products and services offered on the website and make offers
                  to you of additional products and services that the Company
                  believes may be of interest to you; (d) fix any issues with
                  the website, including any technical issues; and (e) Improve
                  the website's functionality, features, and user experience, as
                  well as your ability to do transactions there; (f) manage the
                  Company's relationship with you.
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <span style={{ color: "white" }}>
                By accepting our Privacy Policy or providing information to us,
                you consent us to collect and use your information, as well as
                to process and transmit it as necessary.
              </span>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <span style={{ color: "white" }}>
                You acknowledge and agree that we may access, preserve, and
                disclose any information we may have about you and the contents
                of your account if required to do so by law or in the good faith
                belief that such access, preservation, or disclosure is
                necessary and to comply with legal process, including a search
                warrant, subpoena, statute, or court order, to avoid liability,
                to protect our rights and property, or those of our affiliates
                or the public, or when there is a violation Terms of Service.
              </span>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <span style={{ color: "white" }}>
                WebMobril Gaming Studioz is not obligatory to question or
                challenge the validity of any search warrant, subpoena, or other
                similar governmental request that WebMobril Gaming Studioz
                receives. WebMobril Gaming Studioz may keep your Content copies
                indefinitely, or delete certain Content in part or whole if
                WebMobril Gaming Studioz determines, in its sole discretion,
                that such Content creates an invading or a prohibited posting or
                for any or no reason.
              </span>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  In order to administer, maintain, enhance the services, and
                  develop new services, we also utilize non-personally
                  Identifiable Information and certain technical information
                  about your computer and how you access the Service (including
                  your IP address). WebMobril Gaming Studioz may share
                  information about you with its partners to provide the
                  Service, address customer service issues and correct service
                  errors, communicate with you about the Service, send you
                  advertising and promotional materials related to the Service,
                  and improve your experience on the Service. We do not,
                  however, provide our partners any autonomous authority to
                  divulge this information.
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <span style={{ color: "white" }}>
                We are unable to provide you the choice not to share information
                (whether Personally Identifiable Information or other
                information) with these third parties due to our contractual
                commitments with them and the necessity of sharing information
                to offer and support the Service. The Content that you
                communicate to the service may be viewed by WebMobril Gaming
                Studioz, a few of its partners, and each of their contractors
                and employees in order to offer the service and carry out the
                various responsibilities outlined in this Privacy Policy.
              </span>
            </span>
          </span>
        </p>
        <p align="justify">&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <h3>
                <span style={{ color: "white" }}>
                  Controlling Your Personal Information
                </span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <span style={{ color: "white" }}>
                Please be aware that if you send messages or publish anything
                online that contains personal information, others may collect
                and use such information. Your profile and other personal
                information will be hidden from view if you decide to cancel
                your account at any time. However, after your termination,
                WebMobril Gaming Studioz may (but is not required to) preserve
                information about you that is on its system. To close your
                account or with any other inquiries regarding your personal
                information, kindly get in touch with WebMobril Gaming Studioz.
              </span>
            </span>
          </span>
        </p>
        <p align="justify">&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <h3>
                <span style={{ color: "white" }}>Information by Others</span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  You may choose to give us the email address of another person
                  so they can accept an invitation to join WebMobril Gaming
                  Studioz or so we can make it easier for you to communicate
                  with other users of the Service (like when you upload an
                  address book).
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  For the purpose of distributing information via SNS Apps or
                  Mobile Apps, you may also give us another person's contact
                  information. With the use of this data, we may get in touch
                  with the individual and, if required, remind them that they
                  have been asked to join WebMobril Gaming Studioz or that they
                  have received material through an SNS app or mobile app. Each
                  invitee has the opportunity to opt out of receiving more
                  invites from WebMobril Gaming Studioz. We may be obliged to
                  divulge the information if needed to do so in order to follow
                  the legal procedure mentioned above.
                </span>
              </em>
            </span>
          </span>
        </p>
        <p align="justify">&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <h3>
                <span style={{ color: "white" }}>Cookies</span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  Many of the web pages make use of "cookies," "pixel tags," and
                  "clear gifts" on specific pages to improve your experience
                  with the website. Cookies are text files that the Company
                  stores in the browser of your computer to remember your
                  preferences. Unless you choose to give the Company this
                  information, such as by registering on the website, cookies by
                  themselves do not reveal to the Company your email address or
                  any other personally identifiable information. They may be
                  accessible by either the web server or the user device and are
                  made to store a small bit of data that is unique to a given
                  user and website.
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  However, if you decide to provide the website with personally
                  identifiable information, this data may be connected to the
                  information stored in the cookie. Cookies are used by the
                  company to understand website usage and enhance the website's
                  features and content. The Company may use cookies, for
                  instance, to remember your password in password-protected
                  sections or to personalize your experience on the website
                  (such as by recognizing you by name when you come back). In
                  order to present you with products, services, or programs, the
                  Company may also employ cookies. Additionally, third parties
                  may set cookies on the website; the Company has no control
                  over how these cookies are used.&nbsp;
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  The majority of cookies are session cookies, which are removed
                  from your hard drive once a session is over. You can choose
                  not to accept the cookies if your browser allows you to; but,
                  doing so may prevent you from using some elements of the
                  website and force you to constantly input your password. You
                  can decide to leave the cookie enabled.
                </span>
              </em>
            </span>
          </span>
        </p>
        <p align="justify">&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <h3>
                <span style={{ color: "white" }}>Use of Information</span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  WebMobril Gaming Studioz's games, mobile apps, and social
                  networking apps may contain links to other websites as well as
                  content hosted by other websites. Additionally, as previously
                  said, the majority of the material is provided by WebMobril
                  Gaming Studioz Games or SNS Apps are provided by or hosted on
                  other websites. These other websites uphold their own privacy
                  rules with regard to cookies and collecting and using personal
                  data. It is your responsibility to review such policies.&nbsp;
                </span>
              </em>
            </span>
          </span>
        </p>
        <p align="justify">&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <h3>
                <span style={{ color: "white" }}>
                  Control over Your Personal Information
                </span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  You have the right to revoke your permission at any point,
                  provided that the Company is notified in writing and given the
                  appropriate notice by sending an email to
                </span>
              </em>
              <span style={{ color: "#0000ff" }}>
                <u>
                  <a href="mailto:info@wmgamingstudioz.com">
                    {" "}
                    info@wmgamingstudioz.com
                  </a>
                </u>
              </span>
              <em>
                <span style={{ color: "white" }}>
                  . You can write to the Company if you want to update your
                  personal information at any point in time. Once you decide not
                  to share the Personal Information that the Company has
                  collected, the Company will have the option of not using the
                  Personal Information for the purposes for which it was
                  requested, and it may prevent you from using the website's
                  services or the website itself.&nbsp;
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  In line with the terms of service and/or any other agreement
                  you may have with the Company, you are free to delete your
                  account at any time and stop using the services that the
                  Company offers on the website. In other situations, you can
                  stop utilizing the services it offers on the website by
                  writing to the company at{" "}
                </span>
              </em>
              <span style={{ color: "#0000ff" }}>
                <u>
                  <a href="mailto:info@wmgamingstudioz.com">
                    info@wmgamingstudioz.com
                  </a>
                </u>
              </span>
              <em>
                <span style={{ color: "white" }}> .</span>
              </em>
            </span>
          </span>
        </p>
        <p align="justify">&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <h3>
                <span style={{ color: "white" }}>
                  Modification/Correction of Personal Information
                </span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  You have the right to see the Personal Information you've
                  submitted on the website and to edit or remove any of it that
                  you've directly provided there. You acknowledge that using the
                  website may be impacted by any such alteration or deletion.
                  Additionally,&nbsp;
                </span>
              </em>
              <span style={{ color: "white" }}>
                it may affect the Company’s ability to provide its services to
                you.
              </span>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  In order to guarantee appropriate service delivery, the
                  Company reserves the right to verify and validate your
                  identity as well as your account information and/or payment
                  information. If the Company believes that providing you with
                  access to or allowing you to rectify, update, or delete your
                  personal information would infringe the rights of another
                  person or would otherwise be unlawful, the Company may limit
                  or deny your request.
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  You can email updates and changes to the Company at{" "}
                </span>
              </em>
              <span style={{ color: "#0000ff" }}>
                <u>
                  <a href="mailto:info@wmgamingstudioz.com">
                    info@wmgamingstudioz.com
                  </a>
                </u>
              </span>
              <em>
                <span style={{ color: "white" }}>
                  {" "}
                  along with the reason explaining why the Personal Information
                  needs to be updated or corrected that the Company may have
                  gathered to provide you with customized services and offers.
                  The Company will use all reasonable measures to implement the
                  adjustments in a timely manner.
                </span>
              </em>
            </span>
          </span>
        </p>
        <p align="justify">&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <h3>
                <span style={{ color: "white" }}>
                  Third-Party Advertising Companies
                </span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>We work with&nbsp;</span>
              </em>
              <span style={{ color: "white" }}>third-party&nbsp;</span>
              <em>
                <span style={{ color: "white" }}>
                  advertising firms to deliver ads on our website as well as on
                  WebMobril Gaming Studioz games, mobile apps, and SNS apps. To
                  target advertising on our site, WebMobril Gaming Studioz
                  Games, Mobile Apps, or SNS Apps, our system and the
                  third-party advertising technology use data from Activity
                  Information, WebMobril Gaming Studioz' cookies, your visits to
                  our site, the WebMobril Gaming Studioz Games you view, and the
                  SNS Apps and Mobile Apps you use.
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  In addition, such third-party advertising technologies may be
                  used by our advertisers to target their ads on both our
                  website and other websites. You could receive one or more
                  specific third-party cookies while you're receiving
                  advertisements. To monitor and improve our online advertising,
                  third-party advertising providers may give us pixel tags (also
                  known as "clear gifs" or "beacons"). When a browser reaches
                  the website where the beacon is hosted, we may detect the
                  cookie from that browser and utilize beacons to find out which
                  banner advertising leads people to a certain website.
                </span>
              </em>
            </span>
          </span>
        </p>
        <br />
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  No Personally Identifiable Information is sent to outside
                  advertising firms by WebMobril Gaming Studioz. Note, however,
                  that if an advertiser requests WebMobril Gaming Studioz to
                  show an advertisement to users with specific characteristics
                  (for example, women ages 18 to 24) or a subset of that group
                  (for example, women ages 18 to 24 who have viewed certain
                  categories of content), and you respond to that advertisement,
                  the third-party advertising company may infer that you fit the
                  profile of the target audience they are trying to reach.
                </span>
              </em>
            </span>
          </span>
        </p>
        <p align="justify">&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <h3>
                <span style={{ color: "white" }}>Security</span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <em>
                <span style={{ color: "white" }}>
                  We safeguard your information using standard, commercially
                  reasonable security procedures, including encryption,
                  firewalls, and SSL (Secure Socket Layers). As a result, the
                  protected Information and use Information we gather are safely
                  kept in our databases. Although encryption technology is
                  effective, it should be noted that no security system is
                  impenetrable. As a result, we cannot guarantee the security of
                  our databases or that the information you provide to us won't
                  be intercepted while being sent to us over the Internet or
                  through wireless communication. Any information you transmit
                  to&nbsp;
                </span>
              </em>
              <span style={{ color: "white" }}>WebMobril&nbsp;</span>
              <em>
                <span style={{ color: "white" }}>
                  is done at your own risk. Use the email address{" "}
                </span>
              </em>
              <span style={{ color: "#0000ff" }}>
                <u>
                  <a href="mailto:info@wmgamingstudioz.com">
                    info@wmgamingstudioz.com
                  </a>
                </u>
              </span>
              <em>
                <span style={{ color: "white" }}>
                  {" "}
                  to get in touch with us if you have any concerns regarding the
                  security of the WebMobril Gaming Studioz website.&nbsp;
                  &nbsp;&nbsp;
                </span>
              </em>
            </span>
          </span>
        </p>
        <p align="justify">&nbsp;</p>
        <p style={{ textAlign: "justify" }}>&nbsp;</p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <h3>
                <span style={{ color: "white" }}>Additional Queries</span>
              </h3>
            </span>
          </span>
        </p>
        <p align="justify">
          <span style={{ "font-family": "'Times New Roman', serif" }}>
            <span style={{ "font-size": "medium" }}>
              <span style={{ color: "white" }}>
                Again, feel free to contact us by sending an email to{" "}
              </span>
              <span style={{ color: "#0000ff" }}>
                <u>
                  <a href="mailto:info@wmgamingstudioz.com">
                    info@wmgamingstudioz.com
                  </a>
                </u>
              </span>{" "}
              <span style={{ color: "white" }}>
                , at any time, if you have further questions about WebMobril
                Gaming Studioz or the Privacy Policy.
              </span>
            </span>
          </span>
        </p>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  );
};

export default PrivacyPolicy;
