import React from 'react'

import Com from './com'
import './worked.css'

const Worked = (props) => {
    return (
        <div className="worked-container">
            <div className='transparent'>
            <span className='Heading colortexter h2er span1'>Top Notch Companies We Worked For</span>
                <div className='grider'>
                    <Com rootClassName="com-root-class-name1" image_src='/playground_assets/home/Companies/i (4).png'></Com>
                    <Com rootClassName="com-root-class-name4" image_src='/playground_assets/home/Companies/i (1).png'></Com>
                    <Com rootClassName="com-root-class-name3" image_src='/playground_assets/home/Companies/i (2).png'></Com>
                    <Com rootClassName="com-root-class-name2" image_src='/playground_assets/home/Companies/i (3).png'></Com>
                    <Com rootClassName="com-root-class-name15" image_src='/playground_assets/home/Companies/i (5).png'></Com>
                    <Com rootClassName="com-root-class-name14" image_src='/playground_assets/home/Companies/i (6).png'></Com>
                    <Com rootClassName="com-root-class-name13" image_src='/playground_assets/home/Companies/i (7).png'></Com>
                    <div className='last_div colortexter border-image'><span>+100</span></div>
                </div>
            </div>
        </div>
    )
}

export default Worked