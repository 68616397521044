import React from 'react'

import PropTypes from 'prop-types'

import './Wall.css'
import WallSlider from './WallSlider'

const Wall = (props) => {

  const style = {
    fontSize: "50px",
    fontFamily: "poppins",
    fontWeight: "600",
  }
  return (
    <>
      <div className='elem1'>
        <div className='transp'>
          <div className='table1'>
            <div className='curtain'></div>
            <div className='table2'>
              <WallSlider />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Wall.defaultProps = {
  rootClassName: '',
}

Wall.propTypes = {
  rootClassName: PropTypes.string,
}

export default Wall
