import React from 'react';
import './styles.css';

const Header = () => (
  <header style={{marginBottom:'30px'}}>
    {/* <h2>Inc. This Morning</h2> */}
    <span className="about-us-text04 colortexter pc40">Blog</span>
    {/* <p>
      awesome place to make oneself <br /> productive and entertained through
      daily updates.
    </p> */}
  </header>
);

export default Header;
