import React from "react";
import { Link } from "react-router-dom";
import Chip from "../../../common/Chip";
import "./styles.css";
import moment from "moment";
import parse from "html-react-parser";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";

const BlogItem = ({ blog }) => {
  return (
    <div className="blogItem-wrap">
      <Link className="blogItem-link" to={`/details/${blog._id}`}>
        <img
          className="blogItem-cover"
          src={`${process.env.REACT_APP_URL}${blog.image}`}
          alt="cover"
        />
      </Link>
      <Chip label={blog.category.name} />
      <Link className="blogItem-link" to={`/details/${blog._id}`}>
        <h3 style={{height:"100px"}}>{blog.title}</h3>
      </Link>
      {/* <p>{blog.content.length>100?parse(blog.content.slice(0, 100)+' ...'):parse(blog.content)}</p> */}
      <div style={{ paddingTop: "15px" }}>
        <FacebookShareButton
          // url={`${process.env.REACT_WEB_URL}details/${blog._id}`}
          url={`https://www.wmgamingstudioz.com/details/${blog._id}`}
          quote={"Dummy text!"}
          hashtag="#muo"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        &nbsp;&nbsp;&nbsp;
        <TwitterShareButton
          // url={`${process.env.REACT_WEB_URL}details/${blog._id}`}
          url={`https://www.wmgamingstudioz.com/details/${blog._id}`}
          quote={"Dummy text!"}
          hashtag="#muo"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        &nbsp;&nbsp;&nbsp;
        <LinkedinShareButton
          // url={`${process.env.REACT_WEB_URL}details/${blog._id}`}
          url={`https://www.wmgamingstudioz.com/details/${blog._id}`}
          quote={"Dummy text!"}
          hashtag="#muo"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
      <footer>
        <div className="blogItem-author">
          <img src="/assets/images/author.jpg" alt="avatar" />
          <div>
            <h6 style={{ fontStyle: "italic" }}>{blog.author.name}</h6>
            <p>{moment(blog.createdAt).format("D MMM YYYY")}</p>
          </div>
        </div>
        <Link className="blogItem-link" to={`/details/${blog._id}`}>
          ➝
        </Link>
      </footer>
    </div>
  );
};

export default BlogItem;
