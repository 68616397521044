import React, { useEffect, useState } from "react";
import Chip from "../../components/common/Chip";
import "./styles.css";
import { request } from "../../../../Api";
import EmptyList from "../../components/common/EmptyList";
import FormHooks from "../../../../Hooks/FormHooks";
import moment from "moment";

const Blog = ({ data, id }) => {
  const [
    valuesObj,
    InputChange,
    OnSubmit,
    setDefaultValue,
    InputError,
    DefaultError,
  ] = FormHooks({
    name: {
      rule: "required|alpha|multiSpaces|min:3",
      field: "First Name",
      defaultValue: "",
    },
    phone: {
      rule: "",
      field: "Phone Number",
      defaultValue: "",
    },
    email: {
      rule: "required|email",
      field: "Email",
      defaultValue: "",
    },
    content: {
      rule: "required|multiSpaces",
      field: "content",
      defaultValue: "",
    },
  });

  const [error, setError] = useState({});
  const [comments, setComments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(false);
  const onSave = async (e) => {
    e.preventDefault();
    const validationFlag = await OnSubmit();
    //setDefaultValue([{ name: "", email: "", phone: null, content: "" }]);
    if (validationFlag) {
      setLoader(true);
      let sendData = {
        blog: id,
        user_email: valuesObj.email,
        user_name: valuesObj.name,
        user_phone: valuesObj.phone,
        comment: valuesObj.content,
      };
      const promise = await request("comment/add", sendData, "post");

      if (promise.error) {
        setLoader(false);
        //console.log("error");
      } else {
        setLoader(false);
        setStatus(true);
        setDefaultValue([{ name: "", email: "", phone: null, content: "" }]);
        //console.log(promise.response);
      }
    }
  };

  useEffect(() => {
    setComments(data);
    //getData();
  }, []);

  return (
    <>
      <h3>{comments.length} Comments</h3>
      {status && (
        <h4 style={{ color: "green" }}>your comment submitted successfully</h4>
      )}
      <h5>Leave a Reply:</h5>
      <div className="row">
        <div className="column">
          <input
            type="text"
            name="name"
            value={valuesObj.name ? valuesObj.name : ""}
            placeholder="Name"
            maxLength={35}
            onChange={InputChange}
          />
          {InputError.name && InputError.name.length > 0 ? (
            <span className="error">
              <em>{InputError.name}</em>
            </span>
          ) : null}
        </div>
        <div className="column">
          <input
            type="text"
            name="email"
            value={valuesObj.email ? valuesObj.email : ""}
            placeholder="Email"
            maxLength={100}
            onChange={InputChange}
          />
          {InputError.email && InputError.email.length > 0 ? (
            <span className="error">
              <em>{InputError.email}</em>
            </span>
          ) : null}
        </div>
        <div className="column">
          <input
            type="number"
            name="phone"
            value={valuesObj.phone ? valuesObj.phone : ""}
            min="0"
            step="1"
            placeholder="Phone"
            onChange={InputChange}
          />
          {InputError.phone && InputError.phone.length > 0 ? (
            <span className="error">
              <em>{InputError.phone}</em>
            </span>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="full">
          <textarea
            name="content"
            placeholder="Comment"
            rows={6}
            onChange={InputChange}
            value={valuesObj.content ? valuesObj.content : ""}
          ></textarea>
          {InputError.content && InputError.content.length > 0 ? (
            <span className="error">
              <em>{InputError.content}</em>
            </span>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="full">
          <button
            type="submit"
            name="save"
            className="savebutton"
            onClick={onSave}
          >
            {loader ? "Please Wait..." : "Save"}
          </button>
        </div>
      </div>
      {comments.length >= 0 ? (
        <div className="row">
          <div className="full">
            <div className="comment-thread">
              {comments.map((comment, index) => {
                return (
                  <>
                    <div className="comment" id="comment-1">
                      <a href="#comment-1" className="comment-border-link">
                        <span className="sr-only">
                          Jump to comment-{index + 1}
                        </span>
                      </a>
                      <div className="comment-heading">
                        <div className="comment-info">
                          <h3>{comment.user_name}</h3>
                          <p className="m-0">
                            {comment.user_email} &bull;{" "}
                            {moment(comment.createdAt).format("D MMM YYYY")}
                          </p>
                        </div>
                      </div>

                      <div className="comment-body">
                        <p style={{ color: "black" }}>{comment.comment}</p>
                      </div>
                    </div>
                    <div style={{borderBottom:"solid 1px", color: "#363434"}}></div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <>{/* <EmptyList /> */}</>
      )}
    </>
  );
};

export default Blog;
