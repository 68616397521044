import React from 'react';
import './App.css';
import Blog from './pages/Blog/index';
import Home from './pages/Home//index';

function Index({id}) {
  return (
    <div className='container'>
      {id?<Blog id={id}/>:<Home />}
    </div>
  )
}

export default Index