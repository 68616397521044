import React from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import "./carrer.css"

const carrer = () => {
  return (
    <>
      <Header />
      <div className='element1'>
        <div className='element2'>
          <h1 className='h1under'>Under Construction</h1>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default carrer