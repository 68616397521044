import React from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import Blog from './blog/Index'
import "./blog.css"

const BlogList = () => {

  return (
    <>
      <Header rootClassName="header1-root-class-name" />
      <div className='showcase'>
        <div className='curtain'>
          <Blog />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default BlogList