import React from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import Rope2 from '../components/Rope/rope2'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Autoplay, Pagination, Navigation } from "swiper";

// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

import "./blog.css"
import Wall from '../components/WallGrid/Wall';

const blog = () => {


  const a1 = [
    '/playground_assets/Blog/banners01.png',
    '/playground_assets/Blog/banners02.png',
    '/playground_assets/Blog/banners03.png',
  ];
  const a2 = [
    '/playground_assets/Blog/banners04.png',
    '/playground_assets/Blog/banners05.png',
    '/playground_assets/Blog/banners06.png',
  ];
  const a3 = [
    '/playground_assets/Blog/banners07.png',
    '/playground_assets/Blog/banners08.png',
    '/playground_assets/Blog/banners09.png',
  ];
  const a4 = [
    '/playground_assets/Blog/banners10.png',
    '/playground_assets/Blog/banners11.png',
    '/playground_assets/Blog/banners12.png',
  ];
  const a5 = [
    '/playground_assets/Blog/banners13.png',
    '/playground_assets/Blog/banners14.png',
    '/playground_assets/Blog/banners15.png',
  ];

  const b1 = [
    'https://play.google.com/store/apps/details?id=com.JCarpi.TheJumpingDino',
    'https://play.google.com/store/apps/details?id=gs.tanksbattle.vn',
    'https://play.google.com/store/apps/details?id=org.tinghood.TpsForMobile_google',
  ]

  const b2 = [
    'https://play.google.com/store/apps/details?id=com.Shootingfruit.com&hl=en_IN&gl=US',
    'https://play.google.com/store/apps/details?id=com.PingMosquito.com&hl=en_IN&gl=US',
    'https://play.google.com/store/apps/details?id=com.eggies.mathgame',
  ]

  const b3 = [
    'https://play.google.com/store/apps/details?id=com.knifeclash.clashofknifes',
    'https://play.google.com/store/apps/details?id=com.ortsoftware.crosscrazeFree',
    'https://play.google.com/store/apps/details?id=com.skycap.colorvalley',
  ]

  const b4 = [
    'https://play.google.com/store/apps/details?id=com.jottit.jottitDown',
    'https://play.google.com/store/apps/details?id=com.moremountains.skytheory',
    'https://apps.apple.com/us/app/gonotes-grandstaff/id1587652827',
  ]

  const b5 = [
    'https://play.google.com/store/apps/details?id=com.strawberryhit.com&hl=en_IN&gl=US',
    '',
    '',
  ]

  const c1 = [
    'Jumping Dino',
    'Tanks Battle - Wifi',
    'Pool Empire',
  ]

  const c2 = [
    'Shooting Fruit',
    'Ping Mosquito',
    'Math Game 2019',
  ]

  const c3 = [
    'Knife master',
    'CrossCraze',
    'Cross Color Valley',
  ]

  const c4 = [
    'Jottit Down',
    'Sky Theory',
    'GoNotes GrandStaf',
  ]

  return (
    <>
      <Header rootClassName="header1-root-class-name" />
      <Wall/>
      {/* <div className='slider'>
        <div className='opacity'>
          <div className='sliderInner'>
            <Swiper
              slidesPerView={5}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper s1"
            >
              <SwiperSlide><img src='/playground_assets/Blog/i (1).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (2).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (3).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (4).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (5).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (6).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (7).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (8).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (9).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (10).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (11).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (12).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (13).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (14).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (15).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (16).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (17).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (18).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (19).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (20).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (21).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (22).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (23).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (24).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (25).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (26).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (27).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (28).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (29).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (30).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (31).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (32).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (33).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (34).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (35).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (36).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (37).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (38).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/i (39).jpg' alt='' /></SwiperSlide>
            </Swiper>
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper s2"
            >
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (1).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (2).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (3).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (4).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (5).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (6).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (7).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (8).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (9).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (10).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (11).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (12).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (13).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (14).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (15).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (16).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (17).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (18).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (19).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (20).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (21).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (22).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (23).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (24).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (25).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (26).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (27).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (28).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (29).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (30).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (31).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (32).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (33).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (34).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (35).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (36).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (37).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (38).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (39).jpg' alt='' /></SwiperSlide>
            </Swiper>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper s3"
            >
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (1).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (2).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (3).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (4).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (5).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (6).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (7).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (8).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (9).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (10).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (11).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (12).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (13).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (14).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (15).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (16).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (17).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (18).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (19).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (20).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (21).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (22).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (23).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (24).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (25).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (26).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (27).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (28).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (29).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (30).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (31).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (32).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (33).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (34).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (35).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (36).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (37).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (38).png' alt='' /></SwiperSlide>
              <SwiperSlide><img src='/playground_assets/Blog/portfolio_All_Screen/i (39).jpg' alt='' /></SwiperSlide>
            </Swiper>
            <div className='mobile'></div>
          </div>
        </div>
      </div> */}
      <div className='showcase'>
        <div className='curtain'>
          <Rope2 i1={a1[0]} i2={a1[1]} i3={a1[2]} l1={b1[0]} l2={b1[1]} l3={b1[2]} a1={c1[0]} a2={c1[1]} a3 ={c1[2]}/>
          <Rope2 i1={a2[0]} i2={a2[1]} i3={a2[2]} l1={b2[0]} l2={b2[1]} l3={b2[2]} a1={c2[0]} a2={c2[1]} a3 ={c2[2]}/>
          <Rope2 i1={a3[0]} i2={a3[1]} i3={a3[2]} l1={b3[0]} l2={b3[1]} l3={b3[2]} a1={c3[0]} a2={c3[1]} a3 ={c3[2]}/>
          <Rope2 i1={a4[0]} i2={a4[1]} i3={a4[2]} l1={b4[0]} l2={b4[1]} l3={b4[2]} a1={c4[0]} a2={c4[1]} a3 ={c4[2]}/>
          {/* <Rope2 i1={a5[0]} i2={a5[1]} i3={a5[2]} l1={b5[0]} l2={b5[1]} l3={b5[2]}/> */}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default blog