import React from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import Blog from './blog/Index'
import "./blog.css"
import { useParams } from 'react-router-dom'

const BlogList = () => {
  const { id } = useParams();
  return (
    <>
      <Header rootClassName="header1-root-class-name" />
      <div className='showcase'>
        <div className='curtain'>
          <Blog id={id}/>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default BlogList