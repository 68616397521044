import React from 'react'
import './WallSlider.css'

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/effect-coverflow/effect-coverflow.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/autoplay/autoplay.min.css";


// import "swiper/css/effect-coverflow";
// import "swiper/css/autoplay";
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import required modules
import { EffectCoverflow, Pagination, Autoplay } from "swiper";

const WallSlider = () => {

    const images = [
        "/playground_assets/Blog/i (1).png",
        "/playground_assets/Blog/i (2).png",
        "/playground_assets/Blog/i (3).png",
        "/playground_assets/Blog/i (4).png",
        "/playground_assets/Blog/i (5).png",
        "/playground_assets/Blog/i (6).png",
        "/playground_assets/Blog/i (7).png",
        "/playground_assets/Blog/i (8).png",
        "/playground_assets/Blog/i (9).png",
        "/playground_assets/Blog/i (10).png",
        "/playground_assets/Blog/i (11).png",
        "/playground_assets/Blog/i (12).png",
        "/playground_assets/Blog/i (13).png",
        "/playground_assets/Blog/i (14).png",
        "/playground_assets/Blog/i (15).png",
        "/playground_assets/Blog/i (16).png",
        "/playground_assets/Blog/i (17).png",
        "/playground_assets/Blog/i (18).png",
        "/playground_assets/Blog/i (19).png",
        "/playground_assets/Blog/i (20).png",
        "/playground_assets/Blog/i (21).png",
        "/playground_assets/Blog/i (22).png",
        "/playground_assets/Blog/i (23).png",
        "/playground_assets/Blog/i (24).png",
        "/playground_assets/Blog/i (25).png",
        "/playground_assets/Blog/i (26).png",
        "/playground_assets/Blog/i (27).png",
        "/playground_assets/Blog/i (28).png",
        "/playground_assets/Blog/i (29).png",
        "/playground_assets/Blog/i (31).png",
        "/playground_assets/Blog/i (32).png",
        "/playground_assets/Blog/i (33).png",
        "/playground_assets/Blog/i (34).png",
        "/playground_assets/Blog/i (35).png",
        "/playground_assets/Blog/i (36).png",
        "/playground_assets/Blog/i (37).png",
        "/playground_assets/Blog/i (38).png",
        "/playground_assets/Blog/i (39).jpg",
    ]

    const listItems = images.map((image) => <SwiperSlide>
    <img src={image} />
</SwiperSlide>);
    return (
        <>
            <Swiper
                spaceBetween={50}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"5"}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
                coverflowEffect={{
                    rotate: 0, 
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                }}
                pagination={false}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                className="mySwiper2"
            >
                {listItems}
            </Swiper>
            <Swiper
                spaceBetween={50}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"3"}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
                coverflowEffect={{
                    rotate: 0, 
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                }}
                pagination={false}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                className="mySwiper3"
            >
                {listItems}
            </Swiper>
            <Swiper
                spaceBetween={50}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"1"}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
                coverflowEffect={{
                    rotate: 0, 
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                }}
                pagination={false}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                className="mySwiper4"
            >
                {listItems}
            </Swiper>
        </>
    )
}

export default WallSlider