import React from 'react'

import PropTypes from 'prop-types'

import './showreel.css'

const Showreel = (props) => {
  return (
    <div className={`showreel-container ${props.rootClassName} `}>
      <div className="showreel-container1">
        <video
          src={props.video_src2}
          controls
          loop
          // autoPlay
          preload="metadata"
          className="showreel-video"
        ></video>
      </div>
    </div>
  )
}

Showreel.defaultProps = {
  rootClassName: '',
  video_src2: '',
}

Showreel.propTypes = {
  rootClassName: PropTypes.string,
  video_src2: PropTypes.string,
}

export default Showreel
