import React, { useEffect, useState } from "react";
//import { useParams } from 'react-router';
import { blogList } from "../../config/data";
import Chip from "../../components/common/Chip";
import EmptyList from "../../components/common/EmptyList";
import "./styles.css";
import { Link } from "react-router-dom";
import { request } from "../../../../Api";
import Comments from "./Comments";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import moment from "moment";
import parse from "html-react-parser";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

const Blog = ({ id }) => {
  //const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [status, setStatus] = useState(null);

  const getData = async () => {
    const promise = await request("blog/detail", { id }, "post");

    if (promise.error) {
      //console.log("error");
      setStatus(false);
    } else {
      setBlog(promise.response);
      console.log("good");
    }
  };

  useEffect(() => {
    getData();
    // let blog = blogList.find((blog) => blog.id === parseInt(1));
    // if (blog) {
    //   setBlog(blog);
    // }
  }, []);

  const MyParsedQuillContent = (quillContent) => {
    // Replace the ql-align-center class with a custom React component or inline style
    const adjustedContent = quillContent.replace(
      /class="(ql-align-center|ql-align-justify)"/g,
      (match, classValue) => {
        if (classValue === "ql-align-center") {
          return 'style="text-align: center;"';
        } else if (classValue === "ql-align-justify") {
          return 'style="text-align: justify;"';
        }
        return match; // Return the original class if not matched (unlikely to happen)
      }
    );

    // Parse the adjusted content using html-react-parser
    const parsedContent = parse(adjustedContent);

    return <div>{parsedContent}</div>;
  };

  return (
    <>
      <Link className="blog-goBack" to="/">
        <span> &#8592;</span> <span>Go Back</span>
      </Link>
      {blog ? (
        <>
          <div className="blog-wrap">
            <header>
              <p className="blog-date">
                Published {moment(blog.createdAt).format("D MMM YYYY")}
              </p>
              <h2>{blog.title}</h2>
              <div
                className="blog-subCategory"
                style={{ marginBottom: "15px" }}
              >
                <Chip label={blog.category.name} />
              </div>
            </header>
            <img
              className="blogItem-cover"
              src={`${process.env.REACT_APP_URL}${blog.image}`}
              alt="cover"
            />
            <div className="ql-editor" style={{fontSize:"16px"}}>{parse(blog.content)}</div>
            <div style={{ paddingTop: "15px" }}>
              <FacebookShareButton
                // url={`${process.env.REACT_WEB_URL}details/${blog._id}`}
                url={`https://www.wmgamingstudioz.com/details/${blog._id}`}
                quote={"Dummy text!"}
                hashtag="#muo"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              &nbsp;&nbsp;&nbsp;
              <TwitterShareButton
                // url={`${process.env.REACT_WEB_URL}details/${blog._id}`}
                url={`https://www.wmgamingstudioz.com/details/${blog._id}`}
                quote={"Dummy text!"}
                hashtag="#muo"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              &nbsp;&nbsp;&nbsp;
              <LinkedinShareButton
                // url={`${process.env.REACT_WEB_URL}details/${blog._id}`}
                url={`https://www.wmgamingstudioz.com/details/${blog._id}`}
                quote={"Dummy text!"}
                hashtag="#muo"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>
          </div>
          <div className="main">
            <Comments data={blog.comments} id={blog._id} />
          </div>
        </>
      ) : (
        <>
          <div className="emptyList-wrap">
            {status === false ? "Blog data not found" : <EmptyList />}
          </div>
        </>
      )}
    </>
  );
};

export default Blog;
